import "core-js/modules/es6.function.name";
import "core-js/modules/es6.number.constructor";
import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es6.regexp.replace";
import "core-js/modules/es6.regexp.split";
import "core-js/modules/web.dom.iterable";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { agentFlowerNameList } from '@/api/ceshi_xiugai/whole';
import { getGroundList, getPlatform as _getPlatform, getGroundAdd, getGroundEdit, getGroundDelete, getGroundRead, getGroundChange, setUpdateStatus, getAllProjectName, getGroundCheck, getPromoteCityList } from '@/api/ceshi_xiugai/popularize';
import { getUserList as _getUserList } from '@/api/updateUserInfo/structure';
import Head from '@/components/head/index';
// import TimeScreeningse from '@/components/timeScreeningse';
// import privateSea from '@/components/privateSea';
import MyExportExcel from '@/components/MyExportExcel/index';
import mailList from '@/components/StaffSelect/mailListRadio';
import { getInfo } from '@/api/login';
export default {
  name: 'extensionProject',
  data: function data() {
    return {
      //系统教程
      tab: 0,
      course: this.$route.query.course,
      editDistributionDialogVisible: false,
      //修改分配方式弹框状态
      distributionStatus: '1',
      //修改分配方式选项默认值
      editDistributionStatus: '1',
      //分配方式状态
      prohibit: false,
      dialogProject: false,
      //系统教程
      title: '落地页管理',
      reverse: true,
      iconShow: true,
      val: '',
      type: '',
      statusData: '',
      customer_create: '',
      order_create: '',
      FollowUpEvel: '',
      enterTime: '',
      enterTimeShow: false,
      screate_time: '',
      ecreate_time: '',
      advert_id: [],
      coustomer: [],
      platformOptions: [],
      advertIdChangeName: '',
      advertIdName: '',
      coustomer_create_id: [],
      coustomerName: '',
      loading: false,
      statusShow: false,
      enterShow: false,
      source_client_id: '',
      //花名id
      flower_Array: [],
      //花名
      // 列表请求参数
      query: {
        page: 1,
        pagesize: 10,
        total: 0 //总条数，不是总页数
      },
      directions: [{
        value: '2',
        label: '非定向数据'
      }, {
        value: '1',
        label: '定向数据'
      }],
      status: [{
        value: '1',
        label: '自动'
      }, {
        value: '2',
        label: '手动'
      }],
      popularize_platform_id: [],
      popularize_platform: '',
      //项目名称
      platformName: [],
      adIdSearch: '',
      enterer: '',
      // 列表请求参数
      ptionse: 1,
      listLoading: true,
      //加载动画
      tableData: [],
      //列表
      ruleForm: {
        id: '',
        popularize_project_id: '',
        advert_id: '',
        popularize_platform_id: '',
        adsense_ids: '',
        adsense_id: '',
        status: 1,
        source_client_id: '',
        coustomer_create_id: '',
        contentlist: [{
          content: "",
          index: 0
        }],
        promote_city_id: ''
      },
      rules: {
        popularize_project_id: [{
          required: true,
          message: '请选择推广项目名称',
          trigger: 'change'
        }],
        popularize_platform_id: [{
          required: true,
          message: '请选择推广平台',
          trigger: 'change'
        }],
        adsense_ids: [{
          required: true,
          message: '请填写落地页ID',
          trigger: 'blur'
        }],
        adsense_id: [{
          required: true,
          message: '请填写落地页ID',
          trigger: 'blur'
        }],
        source_client_id: [{
          required: true,
          message: '请选择数据对应花名',
          trigger: 'change'
        }],
        coustomer_create_id: [{
          required: true,
          message: '请选择数据创建人',
          trigger: 'change'
        }],
        is_directional: [{
          required: true,
          message: '请选择定向数据',
          trigger: 'change'
        }],
        status: [{
          required: true,
          message: '请选择分配方式',
          trigger: 'change'
        }],
        adsense_money: [{
          required: true,
          message: '请填写推广资金',
          trigger: 'blur'
        }],
        promote_city_id: [{
          required: true,
          message: '请选择对比范围',
          trigger: 'change'
        }]
      },
      rangeArr: [],
      charge_type: '',
      vals: '',
      fullscreenLoading: false,
      ids: '',
      staffResult: [],
      mailListVisible: false,
      transferor: '',
      //转移人的userid
      radio: false,
      condition: [],
      contentlistInt: 1
    };
  },
  created: function created() {},
  mounted: function mounted() {
    var _this = this;
    if (this.course != 1) {
      this.getList(this.query.page, this.query.pagesize);
      this.callname = this.$store.getters.callname;
      this.callpass = this.$store.getters.callpass;
      var that = this;
      // 控制表格滚动条
      this.$nextTick(function () {
        var mutationObserver = new MutationObserver(function (mutations) {
          that.setHeight();
        });
        mutationObserver.observe(_this.$refs.multipleTable.bodyWrapper, {
          attributes: true,
          subtree: true
        });
      });
      this.flowerArray(); //花名
      this.getPlatform();
      this.getUserList();
      this.getPlatformList();
      getInfo().then(function (res) {
        _this.charge_type = res.data.charge_type;
      });
    } else {}
  },
  components: {
    Head: Head,
    mailList: mailList,
    MyExportExcel: MyExportExcel
  },
  methods: {
    CityList: function CityList() {
      var _this2 = this;
      getPromoteCityList().then(function (res) {
        console.log(123);
        _this2.ruleForm.promote_city_id = res.data[0].id;
        _this2.rangeArr = res.data;
      });
    },
    handleEdit: function handleEdit(val, key) {
      var _this3 = this;
      //  新增/新增子栏目/编辑弹窗
      this.CityList();
      if (val == 'edit') {
        this.vals = '编辑落地页';
        this.dialogProject = true;
        this.iconShow = false;
        this.ruleForm.id = key.id;
        getGroundRead({
          id: String(key.id)
        }).then(function (respomse) {
          var data = respomse.data;
          _this3.ruleForm.id = data.id;
          _this3.ruleForm.popularize_project_id = String(data.popularize_project_id);
          _this3.ruleForm.popularize_platform_id = data.popularize_platform_id;
          _this3.ruleForm.contentlist = [{
            content: "",
            index: 0
          }];
          // this.ruleForm.advert_ids = data.advert_ids.split(',').map((res) => res * 1);
          data.adsense_id.split(',').forEach(function (item, index) {
            _this3.ruleForm.contentlist[index].content = item;
          });
          _this3.ruleForm.promote_city_id = data.promote_city_id;
          _this3.ruleForm.status = data.status;
          _this3.ruleForm.source_client_id = data.source_client_id;
          _this3.ruleForm.coustomer_create_id = data.coustomer_create_id;
        });
      } else if (val == 'newly') {
        this.vals = '新增落地页';
        this.dialogProject = true;
        this.iconShow = true;
        this.ruleForm.popularize_platform_id = '';
        this.ruleForm.popularize_project_id = '';
        this.ruleForm.source_client_id = '';
        this.ruleForm.contentlist = [{
          content: "",
          index: 0
        }];
      }
    },
    createData: function createData(formName) {
      var _this4 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          if (_this4.vals == '新增落地页') {
            _this4.ruleForm.id = '';
            var ad = [];
            _this4.ruleForm.contentlist.map(function (item) {
              if (item.content != '') {
                ad.push(item.content);
              }
            });
            _this4.ruleForm.adsense_ids = String(ad);
            getGroundAdd(_this4.ruleForm).then(function (respomse) {
              _this4.$notify({
                title: '成功',
                message: '新增成功',
                type: 'success',
                duration: 2000
              });
              _this4.dialogProject = false;
              _this4.ruleForm.adsense_id = '';
              _this4.$refs.ruleForm.resetFields();
              _this4.contentlist = [{
                content: "",
                index: 0
              }];
              _this4.getList(_this4.query.page, _this4.query.pagesize);
            });
          } else if (_this4.vals == '编辑落地页') {
            var _ad = [];
            _this4.ruleForm.contentlist.map(function (item) {
              if (item.content != '') {
                _ad.push(item.content);
              }
            });
            _this4.ruleForm.adsense_id = String(_ad);
            getGroundEdit(_this4.ruleForm).then(function (respomse) {
              _this4.$notify({
                title: '成功',
                message: '编辑成功',
                type: 'success',
                duration: 2000
              });
              _this4.fullscreenLoading = false;
              _this4.dialogProject = false;
              _this4.$refs.ruleForm.resetFields();
              _this4.getList(_this4.query.page, _this4.query.pagesize);
            });
          }
        } else {
          return false;
        }
      });
    },
    cancel: function cancel() {
      this.dialogFormVisible = false;
      this.contentlist = [{
        content: "",
        index: 0
      }];
      this.$refs.ruleForm.resetFields();
    },
    selectRows: function selectRows(selection, row) {
      var ids = [];
      selection.map(function (item) {
        ids.push(item.id);
      });
      this.ids = ids.toString();
    },
    editDistribution: function editDistribution() {
      if (this.ids == '') {
        this.$message({
          message: '请选择落地页',
          type: 'warning'
        });
      } else {
        this.editDistributionDialogVisible = true;
      }
    },
    getNum: function getNum(value) {
      this.ruleForm.adsense_ids = value.replace(/[^\d]/g, '');
      this.ruleForm.adsense_id = value.replace(/[^\d]/g, '');
    },
    getNum2: function getNum2(value) {
      this.ruleForm.adsense_id = value.replace(/[^\d]/g, '');
    },
    // getNums(value) {
    //     this.ruleForm.adsense_money = value.replace(/[^\d]/g, '');
    // },
    getUserList: function getUserList() {
      var _this5 = this;
      _getUserList({
        type: '0',
        get_type: '0'
      }).then(function (res) {
        _this5.coustomer = res.data;
      });
    },
    getPlatformList: function getPlatformList() {
      var _this6 = this;
      getAllProjectName().then(function (res) {
        _this6.platformName = res.data;
      });
    },
    advertIdChanges: function advertIdChanges(val) {
      var id = val.slice(-1);
      this.ruleForm.advert_id = id.toString();
      this.ruleForm.popularize_platform_id = Number(id);
    },
    advertIdChange: function advertIdChange(val) {
      var id = val.slice(-1);
      this.advertIdChangeName = id.toString();
      // this.getList(this.query.page,this.query.pagesize)
    },
    coustomerChange: function coustomerChange(val) {
      this.coustomerName = val;
      // this.getList(this.query.page,this.query.pagesize)
    },
    platformChange: function platformChange(val) {
      this.popularize_platform = val;
    },
    handleStatus: function handleStatus(val) {
      var _this7 = this;
      this.statusData = val;
      this.$nextTick(function () {
        _this7.query.page = 1;
        _this7.getList(_this7.query.page, _this7.query.pagesize);
      });
    },
    clearStatus: function clearStatus() {
      var _this8 = this;
      this.statusData = '';
      this.$nextTick(function () {
        _this8.query.page = 1;
        _this8.getList(_this8.query.page, _this8.query.pagesize);
      });
    },
    handleEnterer: function handleEnterer(val) {
      var _this9 = this;
      this.enterer = val;
      this.$nextTick(function () {
        _this9.query.page = 1;
        _this9.getList(_this9.query.page, _this9.query.pagesize);
      });
    },
    clearEnterer: function clearEnterer() {
      var _this10 = this;
      this.enterer = '';
      this.$nextTick(function () {
        _this10.query.page = 1;
        _this10.getList(_this10.query.page, _this10.query.pagesize);
      });
    },
    getList: function getList(page, pagesize) {
      var _this11 = this;
      //账号客户列表

      this.listLoading = true;
      getGroundList({
        page: String(page),
        pagesize: String(pagesize),
        popularize_platform_id: this.advertIdChangeName,
        //项目名称
        source_client_id: this.source_client_id,
        //花名id
        coustomer_create_id: this.coustomerName,
        //创建人
        popularize_project_id: this.popularize_platform,
        adsense_id: this.adIdSearch,
        status: this.statusData,
        create_id: String(this.enterer),
        start_create_time: this.screate_time,
        end_create_time: this.ecreate_time
      }).then(function (respomse) {
        _this11.tableData = respomse.data.data;
        _this11.editDistributionStatus = respomse.data.status + '';
        _this11.distributionStatus = respomse.data.status + '';
        _this11.query.total = respomse.data.total;
        _this11.listLoading = false;
        // this.ruleForm.status = respomse.data.status+''
      }).catch(function () {
        _this11.tableData = [];
        _this11.listLoading = false;
      });
    },
    formatBumen: function formatBumen(row) {
      if (row.structre_arr) {
        var arr = [];
        row.structre_arr.forEach(function (item) {
          arr.push(item.name);
        });
        return arr.join('，');
      }
    },
    formatStatus: function formatStatus(row) {
      if (row.status == 1) {
        return '自动';
      } else if (row.status == 2) {
        return '手动';
      } else {
        return '-';
      }
    },
    flowerArray: function flowerArray() {
      var _this12 = this;
      //花名
      agentFlowerNameList().then(function (res) {
        _this12.flower_Array = res.data;
      });
    },
    // 设置滚动条相关信息1111111111
    setHeight: function setHeight() {
      var widthValue = document.getElementById('customerTable').clientWidth + 'px';
      this.tableBodyWidth = document.getElementById('customerTable').childNodes[1].childNodes[0].clientWidth + 'px';
      var div = document.getElementById('tableLeft');
      div.style.width = widthValue;
      var div2 = document.getElementById('bodyLeft');
      div2.style.width = this.tableBodyWidth;
    },
    // 控制表格滚动条1111111
    exterHandleScroll: function exterHandleScroll() {
      var table = this.$refs.multipleTable.bodyWrapper;
      table.scrollLeft = this.$refs.tableLeft.scrollLeft;
    },
    screening: function screening(item) {
      //昨天/最近七天/30天
      this.value1 = '';
      this.storageTime = item;
      this.publicScreening(this.ptionse, this.storageTime[0], this.storageTime[1]);
    },
    searchOrder: function searchOrder() {
      this.query.page = 1;
      this.getList(this.query.page, this.query.pagesize);
    },
    addTechniqueFun: function addTechniqueFun() {
      this.ruleForm.contentlist.push({
        index: this.contentlistInt++,
        content: ''
      });
    },
    reduceTechniqueFun: function reduceTechniqueFun(num) {
      this.ruleForm.contentlist.splice(num, 1);
    },
    delGround: function delGround() {
      var _this13 = this;
      if (this.ids != '') {
        this.$confirm('落地页数据删除后数据不可恢复，且不再自动接入该落地页数据，确认删除？', '提示', {
          type: 'warning'
        }).then(function () {
          var para = {
            ids: _this13.ids
          };
          getGroundDelete(para).then(function (res) {
            _this13.$notify({
              message: '删除成功',
              type: 'success'
            });
            _this13.query.page = 1;
            _this13.$nextTick(function () {
              //获取列表方法
              _this13.getList(_this13.query.page, _this13.query.pagesize);
            });
          });
        }).catch(function () {});
      } else {
        this.$message('请选中删除项');
      }
    },
    transfer: function transfer() {
      //转移
      if (String(this.ids) == '') {
        this.$message({
          type: 'info',
          message: '请选择数据'
        });
      } else {
        this.radio = true;
        this.mailListVisible = true;
      }
    },
    maillistRadio: function maillistRadio(resultNameArr, result) {
      var _this14 = this;
      //选择转移给谁
      this.$confirm("\u786E\u5B9A\u8F6C\u79FB\u843D\u5730\u9875\u5417?", '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true,
        type: 'warning'
      }).then(function () {
        _this14.listLoading = true;
        _this14.transferor = String(result);
        getGroundChange({
          ids: String(_this14.ids),
          uid: _this14.transferor
        }).then(function (res) {
          _this14.listLoading = false;
          _this14.$notify({
            title: '提示',
            message: '转移成功',
            type: 'success',
            duration: 3000
          });
          _this14.getList(_this14.query.page, _this14.query.pagesize);
        });
      }).catch(function () {
        _this14.$message({
          type: 'info',
          message: '已取消转移'
        });
      });
    },
    idCheck: function idCheck(index) {
      var _this15 = this;
      getGroundCheck({
        id: this.ruleForm.id,
        adsense_id: this.ruleForm.adsense_id
      }).then(function (res) {
        _this15.listLoading = false;
      }).catch(function () {
        _this15.ruleForm.contentlist[index].content = '';
      });
    },
    idCheckl: function idCheckl(val) {
      var _this16 = this;
      getGroundCheck({
        adsense_id: val
      }).then(function (res) {
        _this16.listLoading = false;
      }).catch(function () {});
    },
    //表头显示搜索框
    Activeshow: function Activeshow(item) {
      if (item === '录入人') {
        this.enterShow = true;
      } else if (item === '录入时间') {
        this.enterTimeShow = true;
      } else if (item === '分配方式') {
        this.statusShow = true;
      }
    },
    //点×
    Deletesshow: function Deletesshow(item) {
      this.query.page = 1;
      if (item === '录入时间') {
        this.enterTimeShow = false;
        this.screate_time = '';
        this.ecreate_time = '';
        this.enterTime = '';
        this.getList(this.query.page, this.query.pagesize);
      } else if (item === '分配方式') {
        this.statusShow = false;
        this.statusData = '';
        this.getList(this.query.page, this.query.pagesize);
      } else if (item === '录入人') {
        this.enterShow = false;
        this.enterer = '';
        this.getList(this.query.page, this.query.pagesize);
      }
    },
    submitEnterTime: function submitEnterTime(item) {
      //订单提交时间
      this.query.page = 1;
      if (this.enterTime == null) {
        this.screate_time = '';
        this.ecreate_time = '';
        this.getList(this.query.page, this.query.pagesize);
      } else {
        this.screate_time = this.enterTime[0].toString().substring(0, 10);
        this.ecreate_time = this.enterTime[1].toString().substring(0, 10);
        this.getList(this.query.page, this.query.pagesize);
      }
    },
    flowerArrayEs: function flowerArrayEs(val) {
      // let str = val.map(v => v).join(',');
      // console.log(str);
      // this.getList(this.query.page, this.query.pagesize);
    },
    editDistributionSuccess: function editDistributionSuccess() {
      var _this17 = this;
      setUpdateStatus({
        status: this.distributionStatus,
        ids: this.ids
      }).then(function (respomse) {
        console.log('respomse', respomse.data);
        _this17.$notify({
          title: '成功',
          message: '修改成功',
          type: 'success',
          duration: 2000
        });
        _this17.editDistributionStatus = _this17.distributionStatus;
        _this17.ids = '';
        _this17.query.page = 1;
        _this17.getList(_this17.query.page, _this17.query.pagesize);
      }).catch(function () {});
      this.distributionStatus;
      this.editDistributionDialogVisible = false;
    },
    getPlatform: function getPlatform() {
      var _this18 = this;
      _getPlatform().then(function (respomse) {
        _this18.platformOptions = respomse.data;
      });
    },
    publicScreening: function publicScreening(tab, item1, item2) {
      //公共方法
      this.query.page = 1;
      if (tab == 1) {
        this.start_sea_time = item1;
        this.end_sea_time = item2;
        this.start_follow_time = '';
        this.end_follow_time = '';
        this.getList(this.query.page, this.query.pagesize);
      } else {
        this.start_follow_time = item1;
        this.end_follow_time = item2;
        this.start_sea_time = '';
        this.end_sea_time = '';
        this.getList(this.query.page, this.query.pagesize);
      }
    },
    follower: function follower() {
      // this.getList(this.query.page, this.query.pagesize);
    },
    handleOrder: function handleOrder() {
      this.query.page = 1;
      this.getList(this.query.page, this.query.pagesize);
    },
    handleSizeChange: function handleSizeChange(val) {
      //每页 ${val} 条
      this.getList(this.query.page, val);
      this.query.pagesize = val;
    },
    handleCurrentChange: function handleCurrentChange(val) {
      //当前页
      this.getList(val, this.query.pagesize);
      this.query.page = val;
    },
    getTimestamp: function getTimestamp(time) {
      //把时间日期转成时间戳
      return new Date(time).getTime() / 1000;
    }
  }
};