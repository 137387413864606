var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container CollegeManagement" },
    [
      _vm.course != 1
        ? _c(
            "div",
            { staticStyle: { width: "100%" } },
            [
              _c(
                "el-header",
                { staticStyle: { height: "auto" } },
                [
                  _c(
                    "el-row",
                    { staticStyle: { height: "32px" }, attrs: { gutter: 24 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { xs: 24, sm: 16, md: 8, lg: 8, xl: 6 } },
                        [_c("Head", { attrs: { name: _vm.title } })],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    { staticClass: "btn", attrs: { gutter: 24 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { xs: 24, sm: 16, md: 8, lg: 7, xl: 4 } },
                        [
                          _vm._v(
                            "\n                    推广平台：\n                    "
                          ),
                          _c("el-cascader", {
                            staticStyle: { width: "140px" },
                            attrs: {
                              options: _vm.platformOptions,
                              size: "small",
                              clearable: "",
                              "collapse-tags": "",
                              props: { value: "id", label: "name" },
                              "show-all-levels": false,
                            },
                            on: { change: _vm.advertIdChange },
                            model: {
                              value: _vm.advert_id,
                              callback: function ($$v) {
                                _vm.advert_id = $$v
                              },
                              expression: "advert_id",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { xs: 24, sm: 16, md: 6, lg: 8, xl: 5 } },
                        [
                          _vm._v(
                            "\n                    数据对应花名：\n                    "
                          ),
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "140px" },
                              attrs: {
                                size: "small",
                                filterable: "",
                                placeholder: "请搜索花名",
                                clearable: "",
                              },
                              on: { change: _vm.flowerArrayEs },
                              model: {
                                value: _vm.source_client_id,
                                callback: function ($$v) {
                                  _vm.source_client_id = $$v
                                },
                                expression: "source_client_id",
                              },
                            },
                            _vm._l(_vm.flower_Array, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: {
                                  label: item.flower_name,
                                  value: item.id,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { xs: 24, sm: 16, md: 8, lg: 8, xl: 4 } },
                        [
                          _vm._v(
                            "\n                    数据创建人：\n                    "
                          ),
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "140px" },
                              attrs: {
                                size: "small",
                                clearable: "",
                                filterable: "",
                                placeholder: "请选择",
                              },
                              on: { change: _vm.coustomerChange },
                              model: {
                                value: _vm.coustomer_create_id,
                                callback: function ($$v) {
                                  _vm.coustomer_create_id = $$v
                                },
                                expression: "coustomer_create_id",
                              },
                            },
                            _vm._l(_vm.coustomer, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.realname, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { xs: 4, sm: 4, md: 4, lg: 7, xl: 5 } },
                        [
                          _vm._v(
                            "\n                    推广项目名称：\n                    "
                          ),
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "140px" },
                              attrs: {
                                size: "small",
                                clearable: "",
                                filterable: "",
                                placeholder: "请选择",
                              },
                              on: { change: _vm.platformChange },
                              model: {
                                value: _vm.popularize_platform_id,
                                callback: function ($$v) {
                                  _vm.popularize_platform_id = $$v
                                },
                                expression: "popularize_platform_id",
                              },
                            },
                            _vm._l(_vm.platformName, function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item, value: index },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { xs: 4, sm: 4, md: 4, lg: 5, xl: 4 } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "落地页ID搜索",
                              size: "small",
                              clearable: "",
                            },
                            model: {
                              value: _vm.adIdSearch,
                              callback: function ($$v) {
                                _vm.adIdSearch = $$v
                              },
                              expression: "adIdSearch",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { xs: 4, sm: 4, md: 4, lg: 2, xl: 1 } },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary", size: "small" },
                              on: { click: _vm.searchOrder },
                            },
                            [_vm._v("查询")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        {
                          directives: [
                            {
                              name: "permission",
                              rawName: "v-permission",
                              value: ["新增"],
                              expression: "['新增']",
                            },
                          ],
                          attrs: { xs: 4, sm: 4, md: 4, lg: 2, xl: 1 },
                        },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary", size: "small" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleEdit("newly", "")
                                },
                              },
                            },
                            [_vm._v(" 新增")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        {
                          directives: [
                            {
                              name: "permission",
                              rawName: "v-permission",
                              value: ["分配方式修改"],
                              expression: "['分配方式修改']",
                            },
                          ],
                          staticStyle: { width: "140px" },
                          attrs: { xs: 4, sm: 4, md: 4, lg: 4, xl: 3 },
                        },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary", size: "small" },
                              on: { click: _vm.editDistribution },
                            },
                            [_vm._v("批量修改分配方式")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        {
                          directives: [
                            {
                              name: "permission",
                              rawName: "v-permission",
                              value: ["转移"],
                              expression: "['转移']",
                            },
                          ],
                          attrs: { xs: 4, sm: 4, md: 4, lg: 2, xl: 1 },
                        },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary", size: "small" },
                              on: { click: _vm.transfer },
                            },
                            [_vm._v("转移")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        {
                          directives: [
                            {
                              name: "permission",
                              rawName: "v-permission",
                              value: ["删除"],
                              expression: "['删除']",
                            },
                          ],
                          attrs: { xs: 4, sm: 4, md: 4, lg: 2, xl: 1 },
                        },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary", size: "small" },
                              on: { click: _vm.delGround },
                            },
                            [_vm._v("删除")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    { attrs: { gutter: 24 } },
                    [
                      _c("el-col", { attrs: { span: 12 } }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.statusShow,
                                expression: "statusShow",
                              },
                            ],
                            staticStyle: { "margin-top": "15px" },
                          },
                          [
                            _c("span", { staticClass: "nameTitle" }, [
                              _vm._v("分配方式："),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "250px" },
                                attrs: {
                                  placeholder: "请选择",
                                  clearable: "",
                                  size: "small",
                                },
                                on: {
                                  change: _vm.handleStatus,
                                  clear: _vm.clearStatus,
                                },
                                model: {
                                  value: _vm.statusData,
                                  callback: function ($$v) {
                                    _vm.statusData = $$v
                                  },
                                  expression: "statusData",
                                },
                              },
                              _vm._l(_vm.status, function (item, index) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: {
                                    label: item.label,
                                    value: item.value,
                                  },
                                })
                              }),
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.Deletesshow("分配方式")
                                  },
                                },
                              },
                              [_c("i", { staticClass: "el-icon-close" })]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 12 } }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.enterShow,
                                expression: "enterShow",
                              },
                            ],
                            staticStyle: { "margin-top": "15px" },
                          },
                          [
                            _c("span", { staticClass: "nameTitle" }, [
                              _vm._v("录入人："),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "250px" },
                                attrs: {
                                  size: "small",
                                  clearable: "",
                                  filterable: "",
                                  placeholder: "请选择",
                                },
                                on: {
                                  change: _vm.handleEnterer,
                                  clear: _vm.clearEnterer,
                                },
                                model: {
                                  value: _vm.enterer,
                                  callback: function ($$v) {
                                    _vm.enterer = $$v
                                  },
                                  expression: "enterer",
                                },
                              },
                              _vm._l(_vm.coustomer, function (item) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: {
                                    label: item.realname,
                                    value: item.id,
                                  },
                                })
                              }),
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.Deletesshow("录入人")
                                  },
                                },
                              },
                              [_c("i", { staticClass: "el-icon-close" })]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 12 } }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.enterTimeShow,
                                expression: "enterTimeShow",
                              },
                            ],
                            staticStyle: { "margin-top": "15px" },
                          },
                          [
                            _c("span", { staticClass: "nameTitle" }, [
                              _vm._v("录入时间："),
                            ]),
                            _vm._v(" "),
                            _c("el-date-picker", {
                              staticStyle: { width: "360px !important" },
                              attrs: {
                                size: "small",
                                type: "datetimerange",
                                "start-placeholder": "开始日期",
                                "end-placeholder": "  结束日期",
                                "default-time": ["00:00:00", "23:59:59"],
                                "value-format": "timestamp",
                              },
                              on: { change: _vm.submitEnterTime },
                              model: {
                                value: _vm.enterTime,
                                callback: function ($$v) {
                                  _vm.enterTime = $$v
                                },
                                expression: "enterTime",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.Deletesshow("录入时间")
                                  },
                                },
                              },
                              [_c("i", { staticClass: "el-icon-close" })]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-main",
                [
                  [
                    _c(
                      "el-table",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.listLoading,
                            expression: "listLoading",
                          },
                        ],
                        ref: "multipleTable",
                        staticStyle: { width: "100%" },
                        attrs: {
                          data: _vm.tableData,
                          border: "",
                          id: "customerTable",
                          "header-cell-style": {
                            background: "#F9F9F9",
                            color: "#222222",
                          },
                        },
                        on: { "selection-change": _vm.selectRows },
                      },
                      [
                        _c("el-table-column", {
                          attrs: { type: "selection", width: "50" },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          key: 0,
                          attrs: {
                            label: "序号",
                            align: "center",
                            width: "50",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          scope.$index +
                                            (_vm.query.page - 1) *
                                              _vm.query.pagesize +
                                            1
                                        ) + " "
                                      ),
                                    ]),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            3093164208
                          ),
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          key: 1,
                          attrs: {
                            label: "推广项目名称",
                            prop: "popularize_project_name",
                            align: "center",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          key: 2,
                          attrs: {
                            label: "推广平台",
                            prop: "popularize_platform_name",
                            align: "center",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          key: 3,
                          attrs: {
                            label: "落地页ID",
                            prop: "adsense_id",
                            align: "center",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          key: 4,
                          attrs: {
                            label: "数据对应花名",
                            prop: "flower_name",
                            align: "center",
                            width: "140px",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          key: 5,
                          attrs: {
                            label: "数据创建人",
                            prop: "cus_create_realname",
                            align: "center",
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "el-table-column",
                          {
                            key: 6,
                            attrs: {
                              label: "分配方式",
                              prop: "status",
                              align: "center",
                              width: "120px",
                              formatter: _vm.formatStatus,
                            },
                          },
                          [
                            _c("template", { slot: "header" }, [
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    cursor: "pointer",
                                    color: "#1890ff",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.Activeshow("分配方式")
                                    },
                                  },
                                },
                                [_vm._v("分配方式")]
                              ),
                            ]),
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _c(
                          "el-table-column",
                          {
                            key: 11,
                            attrs: {
                              label: "录入人",
                              prop: "create_realname",
                              align: "center",
                              width: "140",
                            },
                          },
                          [
                            _c("template", { slot: "header" }, [
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    cursor: "pointer",
                                    color: "#1890ff",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.Activeshow("录入人")
                                    },
                                  },
                                },
                                [_vm._v("录入人")]
                              ),
                            ]),
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _c(
                          "el-table-column",
                          {
                            key: 12,
                            attrs: {
                              label: "录入时间",
                              prop: "create_time",
                              align: "center",
                              width: "150",
                            },
                          },
                          [
                            _c("template", { slot: "header" }, [
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    cursor: "pointer",
                                    color: "#1890ff",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.Activeshow("录入时间")
                                    },
                                  },
                                },
                                [_vm._v("录入时间")]
                              ),
                            ]),
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            label: "操作",
                            "min-width": "80",
                            align: "center",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-button",
                                      {
                                        directives: [
                                          {
                                            name: "permission",
                                            rawName: "v-permission",
                                            value: ["编辑"],
                                            expression: "['编辑']",
                                          },
                                        ],
                                        attrs: {
                                          type: "primary",
                                          size: "mini",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleEdit(
                                              "edit",
                                              scope.row
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("编辑")]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            977783816
                          ),
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        ref: "tableLeft",
                        attrs: { id: "tableLeft" },
                        on: {
                          scroll: function ($event) {
                            return _vm.exterHandleScroll()
                          },
                        },
                      },
                      [
                        _c("div", {
                          staticStyle: { height: "10px" },
                          attrs: { id: "bodyLeft" },
                        }),
                      ]
                    ),
                  ],
                ],
                2
              ),
              _vm._v(" "),
              _c("pagination", {
                attrs: {
                  "current-page": _vm.query.page,
                  totalPage: _vm.query.total,
                  pageSize: _vm.query.pagesize,
                },
                on: {
                  handleSizeChange: _vm.handleSizeChange,
                  handleCurrentChange: _vm.handleCurrentChange,
                },
              }),
              _vm._v(" "),
              _c("mailList", {
                ref: "staffSelect",
                attrs: {
                  radio: _vm.radio,
                  condition: _vm.condition,
                  visible: _vm.mailListVisible,
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.mailListVisible = $event
                  },
                  maillistRadio: _vm.maillistRadio,
                },
                model: {
                  value: _vm.staffResult,
                  callback: function ($$v) {
                    _vm.staffResult = $$v
                  },
                  expression: "staffResult",
                },
              }),
              _vm._v(" "),
              _c(
                "el-dialog",
                {
                  staticStyle: { margin: "0 auto" },
                  attrs: {
                    title: _vm.vals,
                    visible: _vm.dialogProject,
                    width: "800px",
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.dialogProject = $event
                    },
                    close: _vm.cancel,
                  },
                },
                [
                  _c(
                    "el-form",
                    {
                      ref: "ruleForm",
                      staticClass: "demo-ruleForm",
                      attrs: {
                        model: _vm.ruleForm,
                        rules: _vm.rules,
                        "label-width": "120px",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "推广项目",
                            prop: "popularize_project_id",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                size: "small",
                                clearable: "",
                                filterable: "",
                                placeholder: "请选择",
                              },
                              model: {
                                value: _vm.ruleForm.popularize_project_id,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.ruleForm,
                                    "popularize_project_id",
                                    $$v
                                  )
                                },
                                expression: "ruleForm.popularize_project_id",
                              },
                            },
                            _vm._l(_vm.platformName, function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item, value: index },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "推广平台",
                            prop: "popularize_platform_id",
                          },
                        },
                        [
                          _c("el-cascader", {
                            attrs: {
                              options: _vm.platformOptions,
                              size: "small",
                              "collapse-tags": "",
                              placeholder: "请选择推广平台",
                              clearable: "",
                              props: { value: "id", label: "name" },
                              "show-all-levels": false,
                            },
                            on: { change: _vm.advertIdChanges },
                            model: {
                              value: _vm.ruleForm.popularize_platform_id,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.ruleForm,
                                  "popularize_platform_id",
                                  $$v
                                )
                              },
                              expression: "ruleForm.popularize_platform_id",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm._l(_vm.ruleForm.contentlist, function (item, index) {
                        return _c(
                          "div",
                          { key: index },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "落地页ID",
                                  prop: "contentlist[" + index + "].content",
                                  rules: {
                                    required: true,
                                    message: "请填写落地页ID",
                                    trigger: "blur",
                                  },
                                },
                              },
                              [
                                _c("el-input", {
                                  staticStyle: {
                                    width: "80%",
                                    "margin-right": "20px",
                                    "margin-bottom": "22px",
                                  },
                                  attrs: { maxlength: "200" },
                                  on: {
                                    input: function ($event) {
                                      return _vm.getNum(item.content)
                                    },
                                    blur: function ($event) {
                                      return _vm.idCheck(index)
                                    },
                                  },
                                  model: {
                                    value: item.content,
                                    callback: function ($$v) {
                                      _vm.$set(item, "content", $$v)
                                    },
                                    expression: "item.content",
                                  },
                                }),
                                _vm._v(" "),
                                index == 0
                                  ? _c("span", {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.iconShow,
                                          expression: "iconShow",
                                        },
                                      ],
                                      staticClass: "iconfont icon-jia1",
                                      on: {
                                        click: function ($event) {
                                          return _vm.addTechniqueFun(index)
                                        },
                                      },
                                    })
                                  : _c("span", {
                                      staticClass: "iconfont icon-jian",
                                      on: {
                                        click: function ($event) {
                                          return _vm.reduceTechniqueFun(index)
                                        },
                                      },
                                    }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "分配方式", prop: "status" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.ruleForm.status,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "status", $$v)
                                },
                                expression: "ruleForm.status",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: 1 } }, [
                                _vm._v("自动"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 2 } }, [
                                _vm._v("手动"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "数据对应花名",
                            prop: "source_client_id",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                size: "small",
                                filterable: "",
                                placeholder: "请选择花名",
                                clearable: "",
                              },
                              on: { change: _vm.flowerArrayEs },
                              model: {
                                value: _vm.ruleForm.source_client_id,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.ruleForm,
                                    "source_client_id",
                                    $$v
                                  )
                                },
                                expression: "ruleForm.source_client_id",
                              },
                            },
                            _vm._l(_vm.flower_Array, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: {
                                  label: item.flower_name,
                                  value: item.id,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "数据创建人",
                            prop: "coustomer_create_id",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                size: "small",
                                clearable: "",
                                filterable: "",
                                placeholder: "请选择数据创建人",
                              },
                              model: {
                                value: _vm.ruleForm.coustomer_create_id,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.ruleForm,
                                    "coustomer_create_id",
                                    $$v
                                  )
                                },
                                expression: "ruleForm.coustomer_create_id",
                              },
                            },
                            _vm._l(_vm.coustomer, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.realname, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "撞单对比范围",
                            prop: "promote_city_id",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                size: "small",
                                clearable: "",
                                filterable: "",
                                placeholder: "请选择对比范围",
                              },
                              model: {
                                value: _vm.ruleForm.promote_city_id,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "promote_city_id", $$v)
                                },
                                expression: "ruleForm.promote_city_id",
                              },
                            },
                            _vm._l(_vm.rangeArr, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: {
                                  label: item.city_name,
                                  value: item.id,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "dialog-footer",
                      staticStyle: { "text-align": "right" },
                      attrs: { slot: "footer" },
                      slot: "footer",
                    },
                    [
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function ($event) {
                              _vm.dialogProject = false
                            },
                          },
                        },
                        [_vm._v("取消")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading.fullscreen.lock",
                              value: _vm.fullscreenLoading,
                              expression: "fullscreenLoading",
                              modifiers: { fullscreen: true, lock: true },
                            },
                          ],
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.createData("ruleForm")
                            },
                          },
                        },
                        [_vm._v("确认")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _c(
            "div",
            {
              staticClass: "boxImg",
              staticStyle: { width: "100%", padding: "20px" },
            },
            [_c("system", { attrs: { title: _vm.title, tab: _vm.tab } })],
            1
          ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "修改分配方式",
            visible: _vm.editDistributionDialogVisible,
            width: "400px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.editDistributionDialogVisible = $event
            },
          },
        },
        [
          _c("span", [_vm._v("分配方式：")]),
          _vm._v(" "),
          _c(
            "el-radio",
            {
              attrs: { label: "1" },
              model: {
                value: _vm.distributionStatus,
                callback: function ($$v) {
                  _vm.distributionStatus = $$v
                },
                expression: "distributionStatus",
              },
            },
            [_vm._v("自动")]
          ),
          _vm._v(" "),
          _c(
            "el-radio",
            {
              attrs: { label: "2" },
              model: {
                value: _vm.distributionStatus,
                callback: function ($$v) {
                  _vm.distributionStatus = $$v
                },
                expression: "distributionStatus",
              },
            },
            [_vm._v("手动")]
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.editDistributionDialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.editDistributionSuccess()
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }